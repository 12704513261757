// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {varAdmin, api } from "../servicios/api";
// import Header from '../comp_dashboard/header';
// import Sidebar from '../comp_dashboard/Sidebar';
// import ShowModalEditBue from '../enc_rscbuenas/ModalEditBuenas';
// import { useNavigate } from 'react-router-dom';

// const URI = api + 'rscbuena/';

// const ShowBuenasPracticas = () => {
//   const history = useNavigate();
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const userId = localStorage.getItem('userId');
//   const userRole = localStorage.getItem('userRol');
//   const isAutenticado = localStorage.getItem('isAutenticado');
//   const [encuestas, setEncuesta] = useState([]);
//   const [marcaComercial, setSearchMarca] = useState('');

//   useEffect(() => {
//     getEncuestas();
//   }, []);

//   const handleButtonClick = () => {
//     getEncuestas();
//     setSearchMarca('');
//   };

//   const handleSearchMarcaChange = (e) => {
//     const value = e.target.value;
//     setSearchMarca(value);
//     getEncuestas();
//   };

//   const getEncuestas = async () => {
//     try {
//         let res;
//         if (userRole === varAdmin) {
//             // Si el usuario es un administrador, realiza la búsqueda con la ruta `/search`
//             res = await axios.get(`${URI}search`, {
//               params: {
//                 marca_comercial: marcaComercial
//               },
//         });
//       } else {
//         // Si el usuario no es un administrador, realiza la búsqueda con la ruta `/user/:userId`
//         res = await axios.get(`${URI}user/${userId}`, {
//           params: {
//         marca_comercial: marcaComercial
//           },
//         });
//       }

//       const encuestas = res.data;
//       setEncuesta(encuestas);
//     } catch (error) {
//       console.error(error);
//       // Manejar errores de la solicitud
//     }
//   };

//   return (
//     <div>
//       <div>
//         <>
//           <Header />
//         </>
//         <Sidebar>
//           <div className='mt-2 row'>
//             <div className='cuadro_princal'>
//               <div className='parent-label titulo_cuadro '>Encuesta: RCS Buenas Practicas</div>
//             </div>

//             <div className=' vh-1 d-flex align-items-center col'>
//               <table className='mt-3 table' style={{ textAlign: "left" }}>
//                 <thead className='table-secondary'>
//                   <tr>
//                     <th>Marca Comercial <br />
//                       <input
//                         type="text"
//                         placeholder="Buscar por alias"
//                         value={marcaComercial}
//                         onChange={handleSearchMarcaChange}
//                       />
//                     </th>
//                     <th>observ_buenas<br /></th>
//                     <th>Acciones <br />
//                       <button className='btn btn-success' onClick={handleButtonClick} style={{ marginLeft: '10px' }}>
//                         <i className='fa fa-leaf'></i>
//                       </button>
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {encuestas.map((encuesta) => (
//                     <tr key={encuesta.ID}>
//                       <td> {encuesta.marca_comercial}</td>
//                       <td> {encuesta.observ_buenas}</td>
//                       <td>
//                         <ShowModalEditBue className=' btn btn-success btn-info' id={encuesta.ID} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </Sidebar>
//       </div>
//     </div>
//   )
// }

// export default ShowBuenasPracticas;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { varAdmin, api } from "../servicios/api";
import Header from "../comp_dashboard/header";
import Sidebar from "../comp_dashboard/Sidebar";
import ShowModalEditBue from "../enc_rscbuenas/ModalEditBuenas";
import { useNavigate } from "react-router-dom";

const URI = api + "rscbuena/";

const ShowBuenasPracticas = () => {
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRol");
  const isAutenticado = localStorage.getItem("isAutenticado");
  const [encuestas, setEncuesta] = useState([]);
  const [marcaComercial, setSearchMarca] = useState("");

  useEffect(() => {
    getEncuestas();
  }, []);

  const handleButtonClick = () => {
    getEncuestas();
    setSearchMarca("");
  };

  const handleSearchMarcaChange = (e) => {
    const value = e.target.value;
    setSearchMarca(value);
    getEncuestas();
  };

  const getEncuestas = async () => {
    try {
      let res;
      if (userRole === varAdmin) {
        // Si el usuario es un administrador, realiza la búsqueda con la ruta `/search`
        res = await axios.get(`${URI}search`, {
          params: {
            marca_comercial: marcaComercial,
          },
        });
      } else {
        // Si el usuario no es un administrador, realiza la búsqueda con la ruta `/user/:userId`
        res = await axios.get(`${URI}user/${userId}`, {
          params: {
            marca_comercial: marcaComercial,
          },
        });
      }

      // const encuestas = res.data;
      // setEncuesta(encuestas);
      // Sorting the response data by 'encuesta_id'
      const sortedEncuestas = res.data.sort(
        (a, b) => a.encuesta_id - b.encuesta_id
      );

      setEncuesta(sortedEncuestas); // Set the sorted data to state
    } catch (error) {
      console.error(error);
      // Manejar errores de la solicitud
    }
  };

  return (
    // <div>
    //   <Header />
    //   <Sidebar>
    //     <div >
    //       <div className="row mb-3">
    //         <div className="col-12">
    //           <div className="card">
    //             <div className="card-header text-center">
    //               Encuesta: RCS Buenas Practicas
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       {/* <div className="row"> */}
    //       {/* <div className="col-12"> */}
    //       <div className='table-responsive'>
    //         {/* <table className="table table-responsive"> */}
    //         <table className="table table-striped mt-3 align-middle">
    //           <thead className="table-secondary">
    //             <tr>
    //               <th>
    //                 Marca Comercial <br />
    //                 <input
    //                   type="text"
    //                   placeholder="Buscar por alias"
    //                   value={marcaComercial}
    //                   onChange={handleSearchMarcaChange}

    //                 />
    //               </th>
    //               <th className="d-md-table-cell d-none">Observaciones Buenas<br /></th>
    //               <th style={{ textAlign: "center" }}>
    //                 Acciones <br />
    //                 <button className="btn btn-success w-auto" onClick={handleButtonClick}>
    //                   <i className="fa fa-leaf"></i>
    //                 </button>
    //               </th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {encuestas.map((encuesta) => (
    //               <tr key={encuesta.ID}>
    //                 <td>{encuesta.marca_comercial}</td>
    //                 <td className="d-md-table-cell d-none">{encuesta.observ_buenas}</td>
    //                 <td style={{ textAlign: "center" }}>
    //                   <ShowModalEditBue
    //                     className='btn btn-success'
    //                     id={encuesta.ID}
    //                     isOpen={isModalOpen}
    //                     onClose={() => setIsModalOpen(false)}
    //                     getEncuestas={getEncuestas}
    //                   />
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </table>
    //       </div>
    //       {/* </div> */}
    //     </div>
    //   </Sidebar>
    // </div>

    <div>
      <Header />
      <Sidebar>
        <div className="mt-2 row">
          <div className="cuadro_princal">
            <div className="titulo_cuadro ">RCS Buenas Practicas</div>
          </div>

          {/* <div className='vh-1 d-flex align-items-center col'> */}
          <div className="table-responsive">
            {/* <table className='mt-3 table' style={{ textAlign: "left" }}> */}
            <table className="table table-striped mt-3 align-middle">
              <thead className="table-secondary">
                <tr>
                  <th>
                    Marca Comercial <br />
                    <input
                      type="text"
                      placeholder="Buscar por alias"
                      value={marcaComercial}
                      onChange={handleSearchMarcaChange}
                    />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Campañas
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Personal Mediadion
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Ruido
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Seguridad Vial
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Punto Violeta
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Servicio Responsable
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Reciclaje
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Sello de Calidad
                    <br />
                  </th>
                  <th className="d-md-table-cell d-none">
                    Observaciones Buenas
                    <br />
                  </th>
                  <th style={{ textAlign: "center" }}>
                    Acciones <br />
                    <button
                      className="btn btn-success w-auto"
                      onClick={handleButtonClick}
                    >
                      <i className="fa fa-leaf"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {encuestas.map((encuesta) => (
                  <tr key={encuesta.ID}>
                    <td>{encuesta.marca_comercial}</td>
                    <td className="d-md-table-cell d-none">{encuesta.info_online ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.mediacion_sala ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.ruido ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.seg_vial ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.Punto_viol ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.respon_bebidas ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.reciclaje ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">{encuesta.sello_calidad ? "Si" : "No"}</td>
                    <td className="d-md-table-cell d-none">
                      {encuesta.observ_buenas}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <ShowModalEditBue
                        className="btn btn-success"
                        id={encuesta.ID}
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        getEncuestas={getEncuestas}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ShowBuenasPracticas;
